import tools from "../../../extends/tools";
import apis from "../../common/js/api2";
export default {
    selectGoods() {
        if (this.saveData.rule === 1) {
            this.selectGoodsFlag = true;
            return;
        }
        // 设置选择商品
        if (this.saveData.rule === 2) {
            this.selectGoodsFlag = false;
        }
    },
    init() {
        //获取数据源
        apis.getGoodsGroupsSource().then(res => {
            if (tools.msg(res, this)) {
                for (let i in res.data) {
                    if (res.data[i]) {
                        this.$set(this.source, i, res.data[i]);
                    } else {
                        this.$set(this.source, i, []);
                    }
                }
            }
        }).catch(err => {
            tools.err(err, this);
        });
        //如果是编辑，则获取详情
        let id = this.$route.query.id;
        if (id > 0) {
            //查看详情禁止编辑
            if (this.$route.query.read == 1) {
                this.isRead = true;
            }
            apis.getGoodsGroupsDetail({
                id: id
            }).then(res => {
                if (tools.msg(res, this)) {
                    this.initData(res.data);
                }
            }).catch(err => {
                tools.err(err, this);
            });
        }

    },
    initData(data) {
        this.saveData = tools.copy(data.value);
        this.$set(this.saveData, 'name', data.name);
        this.$set(this.saveData, 'mode_num_copy', tools.setPrice(data.value.mode_num));

        this.goodsList1 = this.makeGoodsList(data.value.goods);
        this.goodsList2 = this.makeGoodsList(data.value.strategy_goods);
    },

    makeGoodsList(goodsList) {
        let tmpList = [];
        if (!goodsList) {
            return tmpList;
        }
        goodsList.forEach((item, key) => {
            for (let i in item) {
                if (i === 'price' || i === 'subtotal') {
                    item[i] = tools.setPrice(item[i]);
                }
            }
            item.sum = item.subtotal;
            item.retail_price = item.price;
            item.id = item.details_id;
            item.goods_specifications_id = item.skuid;
            item.name = item.goods_name;
            item.unit = item.goods_unit_name;
            item.specification_code = item.product_code;
            item.pic = item.sku_pic;
            item.dispose_info = item.sku.join(',');

            tmpList.push(item);
        });

        return tmpList;
    },

    removeLine(goodsList, key) {
        // if (this.goodsList.length <= 1) {
        //     return;
        // }
        goodsList.splice(key, 1);
        this.changeSum();
    },
    // 获取上传的地址
    uploadFile(file) {
        if (file.code === 200) {
            this.enclosure.push(file.font);
        } else {
            this.$message({
                type: 'error',
                message: file.msg
            });
        }
    },
    fleshList() {

    },
    changePrice(item) {
        item.sum = tools.toDecimal2(item.retail_price * item.num);
        this.changeSum();
    },
    changeSum() {
        // this.sumData.sumNum = 0;
        // this.sumData.allSumPrice = 0;
        // this.sumData.sumPrice = 0;
        // this.goodsList.forEach((item, key) => {
        //     this.sumData.sumNum += item.num;
        //     this.sumData.sumPrice += parseFloat(item.retail_price) * item.num;
        // });
        // this.sumData.allSumPrice = tools.toDecimal2(this.sumData.sumPrice + parseFloat(this.saveData.additional_amount_copy));
        // this.sumData.sumPrice = tools.toDecimal2(this.sumData.sumPrice);
    },
    saveSelect(selectData, type = 1) {
        if (selectData.length <= 0) {
            return;
        }
        let goodsList = [];
        if (type === 1) {
            goodsList = this.goodsList1;
        }
        if (type === 2) {
            goodsList = this.goodsList2;
        }

        selectData.forEach((item, key) => {
            let obj = {};
            obj.specification_code = item.specification_code;
            obj.pic = item.pic;
            obj.name = item.goods_info.name;
            obj.dispose_info = item.dispose_info.join(',');
            obj.unit = item.goods_info.unit_info.name;
            obj.date = '';
            obj.num = 1;
            obj.retail_price = tools.setPrice(item.retail_price);
            obj.sum = obj.retail_price;
            obj.goods_specifications_id = item.id;
            //判断是否有有相同的
            let flag = true;
            goodsList.forEach((i, k) => {
                if (!!i.goods_specifications_id && item.id === i.goods_specifications_id) {
                    i.num = i.num + 1;
                    i.sum = tools.toDecimal2(i.retail_price * i.num);
                    flag = false;
                    this.$message({
                        type: 'error',
                        message: '有相同商品，已合并到列表中'
                    })
                }
            });
            //
            if (flag) {
                goodsList.push(obj);
            }
        });

        this.changeSum();
        // console.log(this.goodsList);
    },
    shareExit() {
        this.createShareFlag = false;
    },
    pickGoods(type = 1) {
        if (this.saveData.warehouse_id === '') {

            // this.$message({
            //     type: 'error',
            //     message: '请先选择一个仓库'
            // });
            // return;
        }
        this.listType = type;
        this.createShareFlag = true;
    },
    save(status) {
        //转换列表
        let tmpList = [];
        this.goodsList1.forEach((item, key) => {
            let obj = {
                skuid: item.goods_specifications_id,
            };
            tmpList.push(obj);
        });
        // 活动商品
        this.saveData.goods = JSON.stringify(tmpList);
        // 赠品
        tmpList = [];
        this.goodsList2.forEach((item, key) => {
            let obj = {
                skuid: item.goods_specifications_id,
            };
            tmpList.push(obj);
        });
        this.saveData.strategy_goods = JSON.stringify(tmpList);

        // 如果选择的是满额送，要转换元到分
        if (this.saveData.mode === 1) {
            this.saveData.mode_num = this.saveData.mode_num_copy * 100;
        } else {
            this.saveData.mode_num = this.saveData.mode_num_copy;
        }

        let loader = tools.loading(null, this);
        //获取详情
        let id = this.$route.query.id;
        if (id > 0) {
            this.saveData.id = id;
            apis.updateGoodsGroups(this.saveData, 3).then(res => {
                if (tools.msg(res, this)) {
                    this.$message({
                        type: 'success',
                        message: '修改成功'
                    })
                }
                tools.loading(loader);
            }).catch(err => {
                tools.err(err, this);
                tools.loading(loader);
            });
            return;
        }

        apis.addGoodsGroups(this.saveData, 3).then(res => {
            if (tools.msg(res, this)) {
                this.$message({
                    type: 'success',
                    message: '添加成功！'
                })
                this.$router.push({
                    path: '/goods/combination'
                });
            }
            tools.loading(loader);
        }).catch(err => {
            tools.err(err, this);
            tools.loading(loader);
        });
    }
}