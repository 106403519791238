<template>
    <div class="container" :style="{left:selectSide==='left'?'75px':'0',right:selectSide==='right'?'75px':'0'}" style="overflow:auto;">
        <div style="padding:20px 0px;width:95%;position:relatvie;margin:0 auto;" :style="{height:__tableHeight__+50+'px'}">
            <div style="text-align:center;position:relative;margin-bottom:30px;">
                <span style="font-size:26px;">活动管理</span>
            </div>
            <div  class="add_split">
                <div class="leftBar"></div>
                <div class="leftBarWord">基础信息</div>
            </div>
            <!-- 顶部导航 -->
            <el-form :inline="true" label-width="80px">
                <el-form-item label="活动名称">
                    <el-input 
                        v-model="saveData.name"
                        placeholder="请输入"></el-input>
                </el-form-item>
                <el-form-item label="活动网点">
                    <el-select 
                        v-model="saveData.shop_id"
                        placeholder="请输入">
                        <el-option v-for="(item,key) in source.shop" 
                                :label="item.name"
                                :value="item.id"
                                :key="key"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="活动编号">
                    <el-input 
                        v-model="saveData.number"
                        placeholder="请输入"></el-input>
                </el-form-item>
            </el-form>

            <el-form :inline="true" label-width="80px">
                <el-form-item label="时间类型">
                    <el-select
                        v-model="saveData.action"
                        placeholder="请输入">
                        <el-option v-for="(item,key) in source.action" 
                                :label="item.name"
                                :value="item.id"
                                :key="key"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="开始时间">
                    <el-date-picker
                    v-model="saveData.start"
                    type="datetime"
                    placeholder="开始时间">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="结束时间">
                    <el-date-picker
                    v-model="saveData.end"
                    type="datetime"
                    placeholder="开始时间">
                    </el-date-picker>
                </el-form-item>
            </el-form>

            <div  class="add_split">
                <div class="leftBar"></div>
                <div class="leftBarWord">活动商品</div>
            </div>

            <el-form :inline="true" label-width="80px">
                <el-form-item label="商品范围">
                        <el-radio 
                            v-model="saveData.rule" 
                            @change="selectGoods"
                            v-for="(item,key) in source.rule" :key="key"
                            :label="item.id" border>{{item.name}}</el-radio>
                </el-form-item>
            </el-form>

            <!--  -->
            <div class="attr_row" style="margin:0 0 30px 0;" v-if="saveData.rule === 1">
                <table class="mytable" style="width:95%;">
                    <thead class="thead">
                        <tr class="rows ">
                            <td class="cols" style="width:80px;">
                                <i class="el-icon-s-tools" style="font-size:16px;"></i>
                            </td>
                            <td class="cols">序号</td>
                            <td class="cols">商品编号</td>
                            <td class="cols">商品图片</td>
                            <td class="cols">商品名称</td>
                            <td class="cols">商品规格</td>
                            <!-- <td class="cols">数量</td> -->
                            <td class="cols">单位</td>
                        </tr>
                    </thead>
                    <tbody class="tbody" v-if="goodsList1.length > 0">
                        <tr class="rows purchase" :class="{odd:k%2===0}"  v-for="(i,k) in goodsList1" :key="k">
                            <td class="cols" style="width:80px;">
                                <i class="el-icon-circle-plus" 
                                @click="pickGoods(1)"
                                style="color:red;font-size:18px;cursor: pointer;"></i>
                                <i class="el-icon-remove-outline"
                                @click="removeLine(goodsList1,k)"
                                style="color:red;font-size:18px;margin-left:10px;cursor: pointer;"></i>
                            </td>
                            <td class="cols">{{k+1}}</td>
                            <td class="cols">
                                <span>{{i.specification_code}}</span>
                            </td>
                            <td class="cols">
                                <span v-if="!!i.pic">
                                    <img style="width:30px;height:30px;" :src="baseUrl+'/'+i.pic" alt="">
                                </span>
                            </td>
                            <td class="cols">{{i.name}}</td>
                            <td class="cols">{{i.dispose_info}}</td>
                            <!-- <td class="cols"><el-input-number @change="changePrice(i)" v-model="i.num" size="small" styl></el-input-number></td> -->
                            <td class="cols">{{i.unit}}</td>
                        </tr>
                        <tr class="rows purchase" >
                            <td class="cols">
                                合计： {{goodsList1.length}} 
                            </td>
                            <td class="cols">&nbsp;</td>
                            <td class="cols">&nbsp;</td>
                            <td class="cols">&nbsp;</td>
                            <td class="cols">&nbsp;</td>
                            <td class="cols"></td>
                            <!-- <td class="cols">{{sumData.sumNum}} </td> -->
                            <td class="cols">&nbsp;</td>
                        </tr>
                    </tbody>
                    <tbody class="tbody" v-else>
                        <tr class="rows purchase" >
                            <td class="cols" colspan="11" style="text-align:center;">
                                暂无数据 <i class="el-icon-circle-plus" @click="pickGoods(1)" style="color:red;font-size:18px;cursor:pointer;"></i>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            
            <div  class="add_split">
                <div class="leftBar"></div>
                <div class="leftBarWord">赠送规则</div>
            </div>
            <el-form :inline="true" label-width="80px">
                <el-form-item label="赠送方式">
                        <el-select 
                            v-model="saveData.mode">
                            <el-option v-for="(item,key) in source.mode" 
                                    :label="item.name"
                                    :value="item.id"
                                    :key="key"></el-option>
                        </el-select>
                </el-form-item>
                <el-form-item label="满多少送">
                        <el-input 
                        v-model="saveData.mode_num_copy"
                        placeholder="请输入"></el-input>
                </el-form-item>

                <el-form-item label="限制条件">
                    <el-switch
                    v-model="saveData.is_effect"
                    active-text="对订单提交前指定数量订单生效">
                    </el-switch>
                    <el-input-number
                        size="mini"
                        style="width:100px;margin-left:10px;"
                        v-show="saveData.is_effect"
                        v-model="saveData.effect"
                        placeholder="请输入数量"></el-input-number>
                </el-form-item>
            </el-form>
            <el-form :inline="true" label-width="80px">
                <el-form-item label="赠送规则">
                        <el-select 
                            style="width:200px;"
                            v-model="saveData.implement">
                            <el-option v-for="(item,key) in source.implement" 
                                    :label="item.name"
                                    :value="item.id"
                                    :key="key"></el-option>
                        </el-select>
                </el-form-item>
                <el-form-item label="附加项1">
                    <el-switch
                    v-model="saveData.implement_seller_checkbox"
                    active-text="卖家备注包含关键字">
                    </el-switch>
                    <el-input
                        style="width:140px;margin-left:10px;"
                        v-show="saveData.implement_seller_checkbox"
                        v-model="saveData.implement_seller_value"
                        placeholder="请输入关键字"></el-input>
                </el-form-item>
                <el-form-item label="附加项2">
                    <el-switch
                    v-model="saveData.implement_buyers_checkbox"
                    active-text="买家备注包含关键字">
                    </el-switch>
                    <el-input
                        style="width:140px;margin-left:10px;"
                        v-show="saveData.implement_buyers_checkbox"
                        v-model="saveData.implement_buyers_value"
                        placeholder="请输入关键字"></el-input>
                </el-form-item>
            </el-form>
            <div  class="add_split">
                <div class="leftBar"></div>
                <div class="leftBarWord">活动策略</div>
            </div>
            <el-form :inline="true" label-width="90px">
                <el-form-item label="选择策略">
                        <el-select 
                            style="width:200px;"
                            v-model="saveData.strategy">
                            <el-option v-for="(item,key) in source.strategy" 
                                    :label="item.name"
                                    :value="item.id"
                                    :key="key"></el-option>
                        </el-select>
                </el-form-item>
            </el-form>

            <div class="attr_row" style="margin:0 0 30px 0;" v-if="saveData.strategy === 1 || saveData.strategy === 3">
                <table class="mytable" style="width:95%;">
                    <thead class="thead">
                        <tr class="rows ">
                            <td class="cols" style="width:80px;">
                                <i class="el-icon-s-tools" style="font-size:16px;"></i>
                            </td>
                            <td class="cols">序号</td>
                            <td class="cols">商品编号</td>
                            <td class="cols">商品图片</td>
                            <td class="cols">商品名称</td>
                            <td class="cols">商品规格</td>
                            <!-- <td class="cols">数量</td> -->
                            <td class="cols">单位</td>
                        </tr>
                    </thead>
                    <tbody class="tbody" v-if="goodsList2.length > 0">
                        <tr class="rows purchase" :class="{odd:k%2===0}"  v-for="(i,k) in goodsList2" :key="k">
                            <td class="cols" style="width:80px;">
                                <i class="el-icon-circle-plus" 
                                @click="pickGoods(2)"
                                style="color:red;font-size:18px;cursor: pointer;"></i>
                                <i class="el-icon-remove-outline"
                                @click="removeLine(goodsList2,k)"
                                style="color:red;font-size:18px;margin-left:10px;cursor: pointer;"></i>
                            </td>
                            <td class="cols">{{k+1}}</td>
                            <td class="cols">
                                <span>{{i.specification_code}}</span>
                            </td>
                            <td class="cols">
                                <span v-if="!!i.pic">
                                    <img style="width:30px;height:30px;" :src="baseUrl+'/'+i.pic" alt="">
                                </span>
                            </td>
                            <td class="cols">{{i.name}}</td>
                            <td class="cols">{{i.dispose_info}}</td>
                            <!-- <td class="cols"><el-input-number @change="changePrice(i)" v-model="i.num" size="small" styl></el-input-number></td> -->
                            <td class="cols">{{i.unit}}</td>
                        </tr>
                        <tr class="rows purchase" >
                            <td class="cols">
                                合计： {{goodsList2.length}} 
                            </td>
                            <td class="cols">&nbsp;</td>
                            <td class="cols">&nbsp;</td>
                            <td class="cols">&nbsp;</td>
                            <td class="cols">&nbsp;</td>
                            <td class="cols"></td>
                            <!-- <td class="cols">{{sumData.sumNum}} </td> -->
                            <td class="cols">&nbsp;</td>
                        </tr>
                    </tbody>
                    <tbody class="tbody" v-else>
                        <tr class="rows purchase" >
                            <td class="cols" colspan="11" style="text-align:center;">
                                暂无数据 <i class="el-icon-circle-plus" @click="pickGoods(2)" style="color:red;font-size:18px;cursor:pointer;"></i>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <!-- 悬浮条 -->
        <div class="bottom_bar">
            <div style="width:90%;margin:0 auto;position:relative;">
                <div style="position:absolute;right:0px;top:0px;">
                    <!-- <el-button>取消</el-button> -->
                    <el-button type="primary" @click="save(0)">保存</el-button>
                </div>
            </div>
        </div>

        <!-- 选择商品 -->
        <el-dialog
            title="选择商品"
            :visible.sync="createShareFlag"
            :modal="false"
            :close-on-click-modal="false"
            v-if="createShareFlag"
            width="1180px"
            center>
            <createShare 
                :storeId="saveData.warehouse_id"
                :listType="listType"
                @saveSelect="saveSelect"
                @fleshList="fleshList" @shareExit="shareExit"></createShare>
        </el-dialog>

    </div>
</template>

<script>
import createShare from './creatDialog';
import shareListFlag from './listDialog';
import apis from "../../common/js/api2";
import tools from "../../../extends/tools";
import datas from "./objectData";
import methods from "./method";

export default {
    props:{
        
    },
    inject: ['selectSide'], 
    data(){
        return datas;
    },
    destroyed(){
        this.goodsList = [];
        this.enclosure = [];
        this.sumData = {
            sumNum: 0,
            sumPrice: 0,
            allSumPrice: '0.00',
        };
        this.saveData = tools.copy(this.saveDataInit);
    },
    components:{
        createShare,shareListFlag
    },
    methods:methods,
    created(){
        if(this.saveDataInit === null){
            this.saveDataInit = tools.copy(this.saveData);
        }
        //初始化数据
        this.init();
    }
}
</script>

<style scoped lang="less">
@import url('../../../assets/less/normal');
.bottom_bar{
    min-width:100%;height:55px;background:#fff;position:fixed;line-height: 55px;
    bottom:0px;left:0px;z-index: 99;line-height:55px;text-align: left;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);border:1px solid #EBEEF5;
    .button{
        font-size:16px;margin-left:30px;z-index: 99;top:0px;width:130px;
    }
}
.el-input__inner{
    width:100%;background: #fff !important;;
}
.rows.purchase .cols{
    height: 40px;
}

</style>