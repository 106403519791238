import tools from "../../../extends/tools";
export default {
    dataInit: null,

    // 返回的商品类型
    listType: 1,
    goodsList1: [], //商品范围
    goodsList2: [], //活动规则商品

    baseUrl: tools.baseURL(),
    uploadUrl: tools.baseURL() + 'api/admin/file',
    authorizationBearer: tools.getToken(),
    createShareFlag: false,
    enclosure: [],
    sumData: {
        sumNum: 0,
        sumPrice: 0,
        allSumPrice: 0,
    },
    saveDataInit: null,
    //
    source: {},
    isRead: false,
    selectGoodsFlag: false,
    saveData: {
        name: '',
        shop_id: '',
        number: '',
        action: '',
        start: '',
        end: '',
        rule: '',
        goods: '',
        mode: '',
        mode_num: '',
        mode_num_copy: '',
        is_effect: false,
        effect: 0,
        implement: '',
        implement_seller_checkbox: false,
        implement_seller_value: '',
        implement_buyers_checkbox: false,
        implement_buyers_value: '',
        strategy: '',
        strategy_goods: ''
    },
    showData: {}
}